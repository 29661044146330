<template>
  <div>
    <el-container>
      <el-header>
        <el-row justify="space-between">
          <el-menu :unique-opened="true" :default-active="routepath" router :collapse="isCollapse" mode="horizontal" background-color='#141c23' text-color="#fff" active-text-color="#ffd04b">
            <el-submenu v-for="(item,index) in menulist" :index="item.menuid+''" :key="index" router style="text-align:left">
              <template slot="title">
                <span>{{item.title}}</span>
              </template>
              <el-menu-item-group v-for="(it,ind) in item.children" :index="it.menuid+''" :key="ind">
                <el-menu-item :index="'/'+it.controller+'/'+it.action" style="padding-left:50px;">{{it.title}}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item @click="logout" style='float:right'>退出</el-menu-item>
            <el-menu-item @click="chngPswdDiv = true" style='float:right'>修改密码</el-menu-item>
            <el-menu-item style='float:right;color:red;font-weight:bold;cursor: default;'>{{username}}</el-menu-item>
          </el-menu>
        </el-row>
      </el-header>
      <el-container class="maincontent">
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
      <!-- <el-footer>
        <a href="http://beian.miit.gov.cn">鲁ICP备20002132号-1</a>
      </el-footer>-->
    </el-container>
    <el-dialog :visible.sync="chngPswdDiv" :close-on-click-modal="false">
      <el-form :model="pswdinfo" ref="pswdinfo" label-width="100px">
        <el-form-item label="原密码" :label-width="formLabelWidth" prop="oldpassword">
          <el-input v-model="pswdinfo.oldpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth" prop="newpassword">
          <el-input v-model="pswdinfo.newpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" :label-width="formLabelWidth" prop="cfrmnewpassword">
          <el-input v-model="pswdinfo.cfrmnewpassword" autocomplete="off" type="password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetFormInfo('pswdinfo');chngPswdDiv = false">取 消</el-button>
        <el-button type="primary" @click="chngpswd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      webname: sessionStorage.getItem('webname'),
      menulist: JSON.parse(sessionStorage.getItem("adminmenulist")),
      isCollapse: false,
      chngPswdDiv: false,
      pswdinfo: {
        oldpassword: "",
        newpassword: "",
        cfrmnewpassword: "",
      },
      formLabelWidth: "150px",
      username: sessionStorage.getItem("adminname"),
    };
  },
  methods: {
    ...mapActions({
      changePswd: "auth/changePassword",
    }),
    chngpswd() {
      if (this.pswdinfo.newpassword != this.pswdinfo.cfrmnewpassword) {
        this.$message({
          showClose: true,
          type: "error",
          message: "两次输入的新密码不一致",
        });
      } else {
        this.$axios({
          method: "post",
          url: this.HOST + "/admin/index/chngPswd",
          data: {
            info: this.pswdinfo,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$message({
              showClose: true,
              type: "info",
              message: "修改成功",
            });
            this.resetFormInfo("pswdinfo");
            this.chngPswdDiv = false;
          }
        });
      }
    },
    logout() {
      sessionStorage.removeItem("adminuuid");
      sessionStorage.removeItem("adminname");
      sessionStorage.removeItem("admintoken");
      sessionStorage.removeItem("adminislogin");
      this.$router.push("/login");
    },
    showmenu() {
      this.isCollapse = !this.isCollapse;
    },
    //重置菜单编辑对话框内容
    resetFormInfo(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    ...mapState({
      navList: (state) => state.navBar.navList,
    }),
    routepath() {
      return this.$route.path;
    },
    menu_act() {
      return this.isCollapse ? "菜单" : "收起菜单";
    },
    aside_width() {
      return this.isCollapse ? "50px" : "220px";
    },
  },
  created() { },
};
</script>

<style>
.flex {
  display: flex;
  justify-content: space-between;
  cursor: initial;
}
.el-menu-item.is-active {
  border-right: none;
}
</style>
